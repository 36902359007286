//BASE URLS
export const BASE_URL = 'https://api.grooks.in/'
export const SURVEY_APP_BASE_URL = BASE_URL + 'survey/'
export const BASE_MEDIA_URL = 'https://stg.grooks.in/admin'
export const GROOKS_WEBSITE_URL = 'http://grooks.in/'
export const GROOKS_TERMS_AND_CONDITION = 'https://grooks.notion.site/Grooks-Privacy-Policy-e7b94931c7fe44d695ddbb1bc128e3a9'
export const GROOKS_RESPONSIBLE_PLAY = 'https://grooks.notion.site/Grooks-Responsible-Gaming-9c015b29ca3246f9ae12a8887e22a0f5'

export const API_V1 = ''

//END POINTS
export const API_ENDPOINT_SETTING = 'settings/'

export const API_SURVEY_APP_ENDPOINT_POLL = 'polls/'
export const API_SURVEY_APP_ENDPOINT_OPINION ='opinion/'
